import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navbarState, setNavbarState] = useState(false);
  const LinksData = [
    { id: 1, link: "/", linkHolder: "home", hash: false },
    { id: 1, link: "#about", linkHolder: "about", hash: true },
    { id: 1, link: "#media", linkHolder: "media", hash: true },
    { id: 1, link: "#picture", linkHolder: "pictures", hash: true },
    { id: 1, link: "#contact", linkHolder: "contact", hash: true },
  ];

  return (
    <header className="w-full fixed top-0 left-0 z-[99] text-white md:bg-none bg-[maroon]/50 backdrop-blur-sm flex md:flex-row flex-col justify-between lg:px-[89px] md:px-[30px] md:py-[30px] py-3 px-[15px]">
      <div className="md:w-auto w-full justify-between flex items-center">
        <p className="cursor-pointer md:text-3xl text-2xl font-bold">Dr Simon Adozi</p>
        <div
          className="text-xl md:hidden text-white"
          onClick={() => {
            setNavbarState(!navbarState);
          }}
        >
          {!navbarState ? <GiHamburgerMenu /> : <MdOutlineClose className="text-white" />}
        </div>
      </div>
      <nav className={`md:w-[40%] w-full overflow-hidden transition-all md:h-auto h-0  ${navbarState ? "h-auto" : "h-0"}`}>
        <ul className="flex md:flex-row flex-col w-full transition-all justify-between md:items-center items-start md:py-0 md:px-0 pt-12 capitalize">
          {LinksData.map((item, index) => {
            return (
              <Link to={item.link} onClick={() => setNavbarState(false)}>
                <li className={`md:py-0 pb-12`} key={index}>
                  <p>{item.linkHolder}</p>
                </li>
              </Link>
            );
          })}
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
