import React from "react";
import Adozi1 from "../assets/images/Adozi1.webp";
import Adozi2 from "../assets/images/Adozi2.webp";
// import Adozi3 from "../assets/images/Adozi3.webp";
// import Adozi4 from "../assets/images/Adozi4.webp";
// import Adozi5 from "../assets/images/Adozi5.webp";
// import Adozi6 from "../assets/images/Adozi6.webp";
// import Adozi7 from "../assets/images/Adozi7.webp";
// import Adozi8 from "../assets/images/Adozi8.webp";
// import Adozi9 from "../assets/images/Adozi9.webp";
// import Adozi10 from "../assets/images/Adozi10.webp";

const Pictures = () => {
  const PictureData = [Adozi1, Adozi2];

  return (
    <div className="w-full py-24 lg:px-[89px] px-[25px]" id="picture">
      <h1 className="pb-12 md:text-3xl text-2xl font-bold text-green-800">Dr Simon Adozi In Pictures</h1>

      <div className="flex flex-wrap justify-around">
        {PictureData.map((item, index) => {
          return (
            <div data-aos={index % 2 === 0 ? "fade-right" : "fade-left"} className=" flex items-center justify-center w-[80%] h-auto mb-3" key={index}>
              <img className="h-auto w-[500px]" src={item} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Pictures;
