import React from "react";

const Footer = () => {
	const date = new Date().getFullYear();
	return (
		<div className='bg-black/80 py-6 text-white  px-[25px]'>
			<p className='text-xs md:text-sm'>© {date} Made With ❤️ by Bloo Cloud Tek Consult</p>
		</div>
	);
};

export default Footer;
