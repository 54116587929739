import React from "react";
import AdoziAbout from "../assets/images/AdoziAbout.jpg";

const About = () => {
	return (
		<div className='w-full flex justify-center items-center' id='about'>
			<div className='py-24 flex lg:px-[89px] md:px-[30px] px-[25px] justify-around items-center flex-wrap 2xl:w-full md:w-[70%]'>
				<div data-aos='fade-right' className='2xl:w-[45%] w-full flex flex-col items-start 2xl:text-start text-center'>
					<h2 className='md:text-3xl text-2xl font-medium py-12 2xl:text-start text-center w-full'>WHO IS DR. SIMON ADOZI?</h2>
					<p className='md:text-[14px] tracking-wider text-[16px] font-light'>
						Dr. Simon Adozi, is a Real Estate Developer,Investment Coach , a Global thought leader, and an Inspirational Speaker with a passion to help young entrepreneurs build sustainable businesses. <br />
						<br />
						Dr. Simon holds a bachelor’s degree in Soil and Water Engineering and a MBA in Entrepreneurship Management from the prestigious Lagos Business School. <br />
						<br />
						Dr. Simon Adozi is the chairman of Eklisia Holdings and the founder of the top rated Luxury Real estate development company in Nigeria, Adozillion Homes and Realty. A Real Estate Development Company on the mission to redefine TRUST in the Nigerian Luxury Real estate space. <br />
						<br />
						Adozillion Homes founded by Dr. Simon is widely known for its audacious projects; like the most Luxurious Apartments in the most sought after neighborhood in Lagos, The Mufasa Apartments Ikate-Lekki, Monte Carlo Resorts and Residences and Eco-Value Garden City Epe.
						<br />
						<br />
						Dr. Simon’s accomplishments are celebrated with numerous accolades and distinctions including Most efficient Real Estate Development developer of the year 2022 by Betamedia,induction into the illustrious group of Africa's 40-under-40 CEOs,recognition among the 100 Most Influential Youths and a recent inclusion in the list of the 100 most notable peace icons in Africa.
						<br />
					</p>
				</div>
				<div data-aos='fade-left' className='2xl:w-[45%] w-full 2xl:mt-0 mt-12'>
					<img src={AdoziAbout} alt='' />
				</div>
			</div>
		</div>
	);
};

export default About;
