import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import About from "../components/About";
import MediaWatch from "../components/MediaWatch";
import Quotes from "../components/Quotes";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";
import Pictures from "../components/Pictures";

const LandingPage = () => {
	return (
		<div className='w-full'>
			<Navbar />
			<Hero />
			<div className='overflow-hidden'>
				<About />
				<MediaWatch />
				<Quotes />
				<Pictures />
				<Contacts />
			</div>
			<Footer />
		</div>
	);
};

export default LandingPage;
