import React from "react";

const Hero = () => {
  return (
    <div className={`w-full flex justify-center md:h-[90vh] h-[100vh] bg-[maroon]`}>
      <div className="md:w-1/2 w-full bg-blend-darken bg-black/60 flex bg-center bg-cover bg-no-repeat flex-col justify-center md:bg-none bg-hero items-center">
        <div className="w-2/3 flex flex-col items-start">
          <h1 className="text-white md:text-4xl text-3xl font-bold py-3">Dr Simon Adozi</h1>
          <div className="sm:text-3xl text-xl font-light text-start text-yellow-400">
            <p>Real Estate Mogul, Business Strategist, Global thought leader, Inspirational speaker</p>
          </div>
        </div>
      </div>
      <div className="w-1/2 h-full bg-hero md:flex hidden bg-cover bg-no-repeat bg-center"></div>
    </div>
  );
};

export default Hero;
