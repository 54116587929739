import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Quote1 from "../assets/images/Quote1.jpg";
import Quote2 from "../assets/images/Quote2.jpg";
import Quote3 from "../assets/images/Quote3.jpg";
import Quote4 from "../assets/images/Quote4.jpg";
import Quote5 from "../assets/images/Quote5.jpg";
import Quote6 from "../assets/images/Quote6.jpg";
import Quote7 from "../assets/images/Quote7.jpg";
import Quote8 from "../assets/images/Quote8.jpg";
import Quote9 from "../assets/images/Quote9.jpg";
import Quote10 from "../assets/images/Quote10.jpg";
import Quote11 from "../assets/images/Quote11.jpg";

const Quotes = () => {
	const SwiperData = [Quote1, Quote2, Quote3, Quote4, Quote5, Quote6, Quote7, Quote8, Quote9, Quote10, Quote11];

	return (
		<div className='py-24 lg:px-[89px] px-[25px]'>
			<h1 className='pb-12 md:text-3xl text-2xl font-bold text-green-800'>Dr Simon Adozi Quotes in Pictures</h1>
			<div>
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y]}
					breakpoints={{
						"@0.00": {
							slidesPerView: 1,
							spaceBetween: 10,
						},
						"@0.75": {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						"@1.00": {
							slidesPerView: 3,
							spaceBetween: 40,
						},
						"@1.50": {
							slidesPerView: 4,
							spaceBetween: 50,
						},
					}}
					spaceBetween={50}
					slidesPerView={3}
					navigation
					pagination={{ clickable: true }}
					scrollbar={{ draggable: true }}
					onSwiper={(swiper) => console.log(swiper)}
					onSlideChange={() => console.log("slide change")}
				>
					{SwiperData.map((item, index) => {
						return (
							<SwiperSlide key={index}>
								<div>
									<img src={item} alt='' />
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
};

export default Quotes;
