import "./App.css";
import { Route, Routes, BrowserRouter as WebRouter } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ScrollToAnchor from "./components/ScrollToAnchor";
import ScrollToTop from "./ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
	useEffect(() => {
		AOS.init({
			duration: 1500,
		});
		AOS.refresh();
	}, []);
	return (
		<div className='App'>
			<WebRouter>
				<ScrollToTop>
					<Routes>
						<Route index element={<LandingPage />} />
					</Routes>
					<ScrollToAnchor />
				</ScrollToTop>
			</WebRouter>
		</div>
	);
}

export default App;
