import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephoneInbound, BsInstagram } from "react-icons/bs";
import { FiFacebook, FiTwitter, FiLinkedin } from "react-icons/fi";

const Contacts = () => {
	const ContactData = [
		// { id: 1, icon: <BsTelephoneInbound />, name: "Phone Number", value: "08038284915" },
		{ id: 1, icon: <FiFacebook />, name: "Facebook", value: "Simon Adozi" },
		{ id: 1, icon: <BsInstagram />, name: "Instagram", value: "@Simon_Adozi" },
		{ id: 1, icon: <FiTwitter />, name: "Twitter", value: "@Adozi Simon" },
		{ id: 1, icon: <FiLinkedin />, name: "Linkedin", value: "Dr. Simon Adozi" },
		{ id: 1, icon: <AiOutlineMail />, name: "Email", value: "ceo@adozillionhomesng.com" },
	];

	return (
		<div className='py-24 lg:px-[89px] bg-slate-100 px-[25px] flex flex-wrap justify-evenly items-center' id='contact'>
			{ContactData.map((item, index) => {
				return (
					<div className='flex md:w-[24%] lg:w-auto mb-12 sm:w-[46%] w-full flex-col items-center' key={index}>
						<div className='mb-1 text-2xl'>{item.icon}</div>
						<p className='font-semibold text-green-800 mb-1'>{item.name}</p>
						<p>{item.value}</p>
					</div>
				);
			})}
		</div>
	);
};

export default Contacts;
