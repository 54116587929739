import React from "react";

const MediaWatch = () => {
	const MediaData = [
		{ id: 1, link: <iframe className='w-full h-full' width='640' height='360' src='https://www.youtube.com/embed/V-TjcI0-r1s' title='Opportunities In Nigeria&#39;s Housing Sector | Dr. Simon Adozi' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>, linkName: "Opportunities In Nigeria's Housing Sector | Plustv Africa" },
		{ id: 1, link: <iframe className='w-full h-full' width='640' height='360' src='https://www.youtube.com/embed/gAqQMpf6jTc' title='Challenges And Returns Of Properties Investment In Nigeria | Dr. Simon Adozi | Silverbird Today' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>, linkName: "Challenges and Returns of Properties Investment In Nigeria" },
		{ id: 1, link: <iframe className='w-full h-full' width='1280' height='768' src='https://www.youtube.com/embed/bgFhCUCwB0g' title='Stay In The Game | Eklisia EP1 | Dr. Simon Adozi' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>, linkName: "EKLISIA SERIES by Dr. Simon AdoziStay in the Game (Episode 1: Consistency)" },
		{ id: 1, link: <iframe className='w-full h-full' width='1280' height='768' src='https://www.youtube.com/embed/3gYOw97L0i0' title='Consistency | Stay In The Game EP3 | Eklisia Series | Dr. Simon Adozi' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>, linkName: "EKLISIA SERIES by Dr. Simon AdoziStay in the Game (Episode 2: Consistency)" },
		{ id: 1, link: <iframe className='w-full h-full' width='640' height='360' src='https://www.youtube.com/embed/rPAj_-5vtHE' title='Courage | Stay In The Game EP3 | Eklisia | Dr. Simon Adozi' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>, linkName: "EKLISIA SERIES by Dr. Simon AdoziStay in the Game (Episode 3: Courage )" },
		{ id: 1, link: <iframe className='w-full h-full' width='640' height='360' src='https://www.youtube.com/embed/CY6rdVe-c2M' title='Faith | Stay in the Game | Eklisia EP4 | Dr. Simon Adozi' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>, linkName: "EKLISIA SERIES by Dr. Simon AdoziStay in the Game (Episode 2: Faith)" },
		{ id: 1, link: <iframe className='w-full h-full' width='1280' height='720' src='https://www.youtube.com/embed/mHuHUwXZMVA' title='HOW I BECAME THE CEO OF ADOZILLION HOMES #JOURNEY2BEINGCEO' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>, linkName: "Business Mentorship -Becoming a CEO" },
	];
	return (
		<div className='py-24 lg:px-[89px] bg-slate-100 md:px-[30px] px-[25px]' id='media'>
			<h1 className='pb-12 md:text-3xl text-2xl font-bold text-green-800'>Media Watch</h1>
			<div className='flex flex-wrap justify-around items-center'>
				{MediaData.map((item, index) => {
					return (
						<div data-aos='fade-up' className='lg:w-[30%] md:w-[48%] w-full mb-12' key={index}>
							<div className='w-full h-[400px]'>{item.link}</div>
							<p className='text-green-800 my-3 text-xl font-medium'>{item.linkName}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MediaWatch;
